<template lang="pug">
#Waves__container
  #Waves
    #waves_description These waves are made only with css :)
    #container_waves
      #big-waves
        span
        span
        span
        span
        span
        span
      #small-waves
        span
        span
        span
        span
        span
    #container_floating
      .header-text-container
        h1.hero-heading Calendar Z..
        p.text-heading.
          At the moment I am working on a calendar solution which I plan to release to github quite soon.
          <br><br>
          I believe it will surpass the functionalities and customization of Fullcalendar by far
          and as you can see it already looks better than other solutions out there.
          <br><br>
          I would be happy to demonstrate it upon request.
      #tablet
</template>

<script>
export default {
  name: 'Waves',
  setup () {


  },
};
</script>

<style lang="stylus" scoped>
#Waves__container
  position absolute
  top 0
  left 0
  bottom 0
  right 0
  overflow hidden
  display flex
  align-items center
  justify-content center
  #Waves
    position relative
    left 100px
    height 600px
    width 800px
    #waves_description
      position absolute
      font 400 15px Averta
      z-index 1000
      bottom 30px
      right 20px
    #container_waves
      position absolute
      z-index 100
      top 0
      left 0
      bottom 0
      right 0
      border-radius 20px
      background linear-gradient(145deg, #add8ff 0%, white 50%);
      #big-waves
        position absolute
        top -180px
        left 20%
        width 80%
        height 780px
        overflow hidden
        z-index 100
        border-radius 20px
        > span
          position absolute
          top -3300px
          left -1500px
          height 4000px
          width 4000px
          box-sizing border-box
          border-radius 48%
          z-index 200
          opacity .2
          &:nth-child(1)
            animation rotateRight 25000ms infinite linear
            border 50px solid red
          &:nth-child(2)
            animation rotateRight 17000ms infinite linear
            border 50px solid yellow
          &:nth-child(3)
            animation rotateRight 15000ms infinite linear
            border 50px solid blue
          &:nth-child(4)
            animation rotateRight 20000ms infinite linear
            border 50px solid green
          &:nth-child(5)
            box-shadow inset 0 0 20px 0 rgba(0, 0, 255, .6)
            animation rotateLeft 30000ms infinite linear
            border 80px solid white
            opacity 1
            width 4050px
            height 4050px
          &:nth-child(6)
            left -1570px
            border 200px solid white
            opacity 1
            width 4210px
            height 4210px
    #container_floating
      position absolute
      z-index 200
      top 0
      left 0
      bottom 0
      right 0
      user-select none
      cursor default
      perspective 1200px
      > div
        position absolute
        border-radius 15px
        flex-shrink 0
      #tablet
        background-color #F4F6F8
        background-image url('../../public/waves/calendar_2.png')
        background-repeat no-repeat
        background-position center
        background-size contain
        top 5%
        left -200px
        width 650px
        height 450px
        transform rotateX(20deg) rotateY(65deg) rotateZ(-22deg)
        box-shadow: -20px 18px 40px -12px rgba(50, 50, 80, .7), -70px 35px 100px -35px rgba(50, 50, 100, .3);
      .header-text-container
        position absolute
        padding 20px
        top 50px
        right 30px
        width 400px
        z-index 1000
        text-align left
        h1.hero-heading
          position relative
          z-index 10
          font 500 28px / 45px AvertaStd;
          color #282c36
          margin 0 0 24px 0
          &::before
            content ''
            width 42px
            height 5px
            position absolute
            top -20px
            left 0
            background-color #EF3934
          &::after
            z-index -1
            content 'new'
            position absolute
            top 80px
            left -80px
            font 500 70px / 40px AvertaStd;
            width 42px
            height 5px
            color white
            transform rotateZ(-90deg)
        p.text-heading
          -moz-osx-font-smoothing grayscale
          color #3a3b3a
          font 400 15px / 28px Averta, 'Helvetica'
          margin-bottom 24px

/* Keyframes for the animations
   ======================================================================================== */
@keyframes rotateRight
  0%
    transform rotate(360deg)
  100%
    transform rotate(0deg)

@keyframes rotateLeft
  0%
    transform rotate(0deg)
  100%
    transform rotate(360deg)
</style>